import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Rsvp from './pages/Rsvp';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path='/rsvp' element={<Rsvp />} />
      </Routes>
    </Router>
  );
}

export default App;
