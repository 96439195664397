import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import cx from 'classnames';
import * as Yup from 'yup';
import img from './form-img.png';

const Schema = Yup.object({
  Name: Yup.string()
    .required('Name is required'),
  Email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  RSVP: Yup.string()
    .oneOf(['Yes', 'Maybe', 'No'])
    .required('Please, choose one of the options'),
  Amount: Yup.string()
    .when("RSVP", {
      is: rsvp => rsvp === 'Yes' || rsvp === 'Maybe',
      then: Yup.string().required('Please, choose one of the options')
    }),
  Note: Yup.string()
    .when("RSVP", {
      is: rsvp => rsvp === 'Yes' || rsvp === 'Maybe',
      then: Yup.string().required('Please, fill in')
    })
});

const AmountSelect = ({ field, form }) => {
  const onChange=(e) => form.setFieldValue(field.name, e.target.value);
  return (
    <select
      id="Amount"
      value={field.value || ''}
      name="Amount"
      onChange={onChange}
      className="select-field w-select">
      <option value="">How many of you are coming?</option>
      <option value="Just me">Just me</option>
      <option value="The 2 of us">The 2 of us</option>
      <option value="The whole family! ">The whole family! </option>
    </select>
  );
}
const Note = ({ field, form }) => {
  const onChange=(e) => form.setFieldValue(field.name, e.target.value);
  return (
    <textarea onChange={onChange} name="Note" maxLength="5000" id="Note" placeholder="Please list the names of all guests coming with you, including children" className="text-field note w-input"></textarea>
  )
}
const Rsvp = () => {
  const [isSending, setIsSending] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState();

  const handleSubmit = async (values, {resetForm}) => {
    setIsSending(true);
    try {
      const response = await fetch('https://qgh3lmit35m4vbmob5wdskizhq0konpy.lambda-url.us-east-1.on.aws/', { method: 'POST', body: JSON.stringify(values) });
      if (response.ok) {
        setIsSuccess(true);
        resetForm();
      } else if (response.status === 400) {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
      setIsSuccess(false);
    }
    setIsSending(false);
  };
  return (
    <>
      <div className="section">
      <img src={img} alt="" className="invite-logo" />
        <div style={{
            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            opacity: 1,
            transformStyle: 'preserve-3d'
          }}
          className="div-block-16">
          <div className="form-block w-form">
          <Formik
              initialValues={{
                Name: '',
                Email: '',
                RSVP: '',
                Amount: '',
                Note: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={Schema}
            >
            {({ errors, touched, isSubmitting, values }) => (
              <Form name="wf-form-RSVP-form" className="form">
              <h3 className="heading">
                We would love to see you celebrating with us!
              </h3>
              <Field type="text" className="text-field w-input" maxLength="256" name="Name" placeholder="Your name" id="Name-3" />
              {
                touched.Name && errors.Name && <span className="error-message">Name is required</span>
              }
              <Field type="email" className="text-field w-input" maxLength="256" name="Email" placeholder="Email for updates" id="Email-3" />
              {
                touched.Email && errors.Email && <span className="error-message">Email is required</span>
              }
              <div className="div-block-6">
                <label className={cx("radio-button-field", "w-radio", values.RSVP === 'Yes' ? 'selected': '')}>
                  <Field type="radio" id="Yes" name="RSVP" value="Yes" required="" className="w-form-formradioinput radio-button w-radio-input" />
                  <span htmlFor="Yes" className="radio-button-label w-form-label">
                    I am joining!
                  </span>
                </label>
                <label className={cx("radio-button-field", "w-radio", values.RSVP === 'Maybe' ? 'selected': '')}>
                  <Field type="radio" id="Maybe" name="RSVP" value="Maybe" className="w-form-formradioinput radio-button w-radio-input" />
                  <span htmlFor="Maybe" className="radio-button-label w-form-label">Not sure yet</span>
                </label>
                <label className={cx("radio-button-field", "w-radio", values.RSVP === 'No' ? 'selected': '')}>
                  <Field type="radio" id="No" name="RSVP" value="No" className="w-form-formradioinput radio-button w-radio-input" />
                  <span htmlFor="No" className="radio-button-label w-form-label">Sorry, I can't join</span>
                </label>
              </div>
              {
                touched.RSVP && errors.RSVP && <span className="error-message">Please select one of the options</span>
              }
              <Field id="Amount" name="Amount" component={AmountSelect} />
              {
                touched.Amount && errors.Amount && <span className="error-message">Please select one of the options</span>
              }
              <Field id="Note" name="Note" component={Note} />
              {
                touched.Note && errors.Note && <span className="error-message">Note is required</span>
              }
              <input type="submit" value={isSending ? "Please wait..." : "Send"} className="form-button w-button" />
            </Form>
            )}
          </Formik>
          {
            isSuccess && (
              <div className="success-message w-form-done">
                <div className="text-block">THANK YOU!<br /><br />&zwj;<br />
                  <span className="text-span-3">♥</span>
                  <br />
                </div>
                <div className="text-block-6">Michael &amp; Kelly</div>
              </div>
            )
          }
          {
            isSuccess === false && (
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            )
          }
        </div>
        <Link to='/' className="text-block-5">CLOSE</Link>
      </div>
      <div id="anime-js" className="anime"></div>
    </div>
    <div className="mobile-cover"><div>Rotate the phone to get the full experience</div></div>
    </>
  );
};

export default Rsvp;
