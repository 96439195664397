import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import bg from './bg.jpg';
import st1 from './star1.png';
import st2 from './star2.png';
import st3 from './star3.png';

const Home = () => {
  const inviteWrapRef = React.useRef();
  const invitationCTAS = React.useRef();
  const onOpenClick = () => {
    if (inviteWrapRef.current) {
      inviteWrapRef.current.style.transform = 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(150deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)';
    }
    if (invitationCTAS.current) {
      invitationCTAS.current.style.opacity = 1;
    }
  }
  const onCloseClick = () => {
    if (inviteWrapRef.current) {
      inviteWrapRef.current.style.transform = 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)';
    }
    if (invitationCTAS.current) {
      invitationCTAS.current.style.opacity = 0;
    }
  }
  return (
    <>
    <div className="wrapper">
      <div
        style={{
          opacity: 1,
          transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
          transformStyle: 'preserve-3d',
        }}
        className="invite-wrap"
      >
        <div
          style={{
            transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(150deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d',
            transition: 'transform 2s',
          }}
          className="invite-cover"
          ref={inviteWrapRef}
        >
          <div className="front-image-design" style={{ backgroundImage: `url(${bg})`}}>
            <div
              style={{
                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d'
              }}
              className="cover-txt-circle"
            >
              <div className='st1' style={{ backgroundImage: `url(${st1})` }}></div>
              <div className='st2' style={{ backgroundImage: `url(${st2})` }}></div>
              <div className='st3' style={{ backgroundImage: `url(${st3})` }}></div>
              <div className="cover-text-wrap">
                <div className={cx("cover-txt", "aleo-regular")}>SAVE <span className='aleo-light'>THE</span> DATE</div>
              </div>
                <div className="cover-txt-name">Michael</div>
                <div className={cx("aleo-light", 'cover-txt-and')}>and</div>
                <div className="cover-txt-name">Kelly</div>
                <div className="wedding-txt">Wedding</div>
              <div className="cover-text-wrap">
                <div className={cx('date', 'aleo-light')}>MON &nbsp;
                  <span className={cx('aleo-bold')}>6</span>&nbsp; FEB
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              opacity: 1,
              transformStyle: 'preserve-3d'
            }}
            className="cover-button-wrap"
          >
            <button style={{ opacity: 1 }} onClick={onOpenClick} className="button w-button">Open invitation</button>
          </div>
        </div>
        <div className="invite" style={{ backgroundImage: `url(${bg})`, opacity: 1}}>
          <div ref={invitationCTAS} style={{ transform: 'translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', transition: 'opacity 2s' }} className="invite-buttons-wrap">
            <Link id="wishbutton" to="/rsvp" className="button w-button" style={{ maxWidth: '164px', textTransform: 'uppercase' }}>RSVP</Link>
            <button id="wishbutton" onClick={onCloseClick} className="button w-button" style={{ maxWidth: '164px', textTransform: 'uppercase' }}>Close</button>
          </div>
          <div className="invite-content">
            <div className="w-names">Michael</div>
            <div className="w-and">and</div>
            <div className="w-names">Kelly</div>
            <div className="general-txt" style={{ margin: '10px 0' }}>Invite you to join in&nbsp;the celebration of their</div>
            <div className="w-names">Wedding</div>
            <div className="general-txt">Monday 3:00 PM </div>
            <div className="general-txt-date" style={{ marginTop: '20px' }}>February 6th, 2023</div>
            <a href="https://goo.gl/maps/Q5Aj7tDndkVtXXov8" target="_blank" className="general-txt" style={{ marginTop: '20px' }}>
              Bel-Air Bay Club
              <br />
              16801 Pacific Coast Highway
              <br />
              Pacific Palisades, California 90272
            </a>
          </div>
        </div>
      </div>
      </div>
      <div className="mobile-cover">
        <div>Rotate the phone to get the full experience</div>
      </div>
    </>
  )
}

export default Home;
